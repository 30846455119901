import { IBuyPoFilter } from '../interface/BuyPoInterface';
import { LabelConstant } from '../../../../../utils/LabelConstant';
import { platform } from 'os';

export const BuyPoConstants = {
    header: {
        create: 'Create a Buy PO',
        update: 'Update a Buy PO',
    },
    labels: {
        edit: 'Edit',
        addNew: 'Add New',
        createBuyPo: 'Create Buy PO',
        updateBuyPo: 'Update Buy PO',
        cattlePaid: 'Cattle Paid',
        freightPaid: 'Freight Paid',
        cattleReceived: 'Cattle Received',
        next: 'Next',
        delete: 'Delete',
        close: 'Save and Close',
        submit: 'Submit',
        createdBy: 'Created by',
        saved: 'Saved',
        submittedBy: 'Submitted by',
        modifiedBy: 'Last Modified by',
    },
    form: {
        checkNumber: {
            label: 'Check #',
            maxLength: 30,
        },
        invoiceNumber: {
            label: 'Inter Co. Invoice #',
            maxLength: 30,
        },
        holdPaymentReason: {
            label: 'Hold Payment Reason',
        },
        cattlePaid: {
            label: 'Cattle Paid',
            name: 'isCattlePaid',
        },
        freightPaid: {
            label: 'Freight Paid',
            name: 'isFreightPaid',
        },
        cattleReceived: {
            label: 'Cattle Received',
            name: 'isCattleReceived',
        },
        holdPayment: {
            label: 'Hold Payment',
            name: 'isPaymentHold',
        },
    },
    moduleType: 'BUY_PO',
    missingFieldMessage:
        'You are missing required fields. See the items in red.',
    headCountErrorMessage: (
        <p>
            Please fill in either <b>'Head Count'</b> or{' '}
            <b>'Estimated Head Count'</b>
        </p>
    ),
    grossWeightOrPayWeightErrorMessage: (
        <p>
            Please enter <b>'Gross Weight'</b> or <b>'Pay Weight'</b>
        </p>
    ),
    attachmentFileLengthErrorMessage: 'You can only select up to ',
    attachmentFileSizeErrorMessage: 'Each file must be no larger than ',
    duplicateFileErrorMessage:
        'A file with the same name already exists. Do you want to replace it?  ',
};

export const BuyPOGlobalInfoConstants = {
    header: 'Create a Buy PO',
    title: 'Global Information',
    totalNOFiles: 10,
    labels: {
        step1: 'Step 1',
    },
    modalType: {
        buyLocation: 'Buy Location',
        destination: 'Destination',
    },
    globalInfoModalFields: [
        'buyPoLocation',
        'buyPoDestination',
        'buyPoTruckingCompany',
        'buyPoBuyer',
        'buyPoBuyer2',
        'buyPoKillGroup',
        'buyPoPayee',
    ],
    selectedPickupDateRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    },
    form: {
        buyPO: {
            label: 'Buy PO',
            mobileLabel: 'Buy Po',
            placeholder: '(auto-generate)',
        },
        purchaseDate: {
            label: 'Purchase Date',
            mobileLabel: 'Purchase Date',
            fieldName: 'buyPoPurchaseDate',
        },
        pickupDate: {
            label: 'Pickup Date',
            mobileLabel: 'Pickup Date',
            fieldName: 'buyPoPickupDate',
        },
        pickupDateRange: {
            label: 'Pickup Date Range',
            mobileLabel: 'Pickup Date Range',
            placeholder: 'Select Pickup Date Range',
        },
        location: {
            label: 'Buy Location',
            mobileLabel: 'Buy Location',
            fieldName: 'buyPoLocation',
            placeholder: 'Select Location',
            isRequired: true,
            activateModalConstant: {
                heading: 'Activate Location',
                text: ' is currently Inactive. Activate and use this location.',
            },
        },
        destination: {
            label: 'Destination',
            mobileLabel: 'Destination',
            placeholder: 'Select Destination',
            fieldName: 'buyPoDestination',
            isRequired: true,
            activateModalConstant: {
                heading: 'Activate Destination',
                text: ' is currently Inactive. Activate and use this destination.',
            },
        },
        truckingCompany: {
            label: 'Trucking Company',
            mobileLabel: 'Trucking Company',
            placeholder: 'Select Company',
            fieldName: 'buyPoTruckingCompany',
            idField: 'companyId',
            invalid: '',
            isRequired: true,
            activateModalConstant: {
                heading: 'Activate Trucking Company',
                text: ' is currently Inactive. Activate and use this Trucking Company.',
            },
        },
        killGroup: {
            headingLabel: {
                create: 'Create Kill Group',
                update: 'Update Kill Group',
            },
            label: 'Kill Group',
            mobileLabel: 'Kill Group',
            fieldName: 'buyPoKillGroup',
            idField: 'killGroupId',
            placeholder: 'Select Kill Group',
            invalid: '',
            activateModalConstant: {
                heading: 'Activate Kill Group',
                text: ' is currently Inactive. Activate and use this Kill Group.',
            },
        },
        buyer: {
            headingLabel: 'Create New Buyer',
            label: 'Buyer',
            placeholder: 'Select Buyer',
            mobileLabel: 'Buyer',
            fieldName: 'buyPoBuyer',
            idField: 'buyerId',
            invalid: '',
        },
        buyer2: {
            headingLabel: 'Create New Buyer',
            label: 'Second Buyer',
            placeholder: 'Select Buyer',
            mobileLabel: 'Buyer',
            fieldName: 'buyPoBuyer2',
            idField: 'buyerId',
            invalid: '',
        },
        payee: {
            headingLabel: 'Create New Payee',
            label: 'Payee',
            mobileLabel: 'Payee',
            fieldName: 'buyPoPayee',
            idField: 'id',
            placeholder: 'Select Payee',
            invalid: '',
        },
        attachment: {
            label: 'Attach Document',
            invalid: '',
            button: 'Choose files',
        },
        orderNotes: {
            name: 'orderNotes',
            label: 'Notes',
        },
        DOA: {
            name: 'DOA',
            label: 'DOA',
        },
        cut: {
            name: 'cutCount',
            label: 'Cut',
        },
    },
    dynamicFields: {
        buyerName: {
            key: 'name',
            label: 'Buyer Name',
            valueType: 'string',
            maxLength: 50,
        },
        buyerContactNumber: {
            key: 'contactNumber',
            label: 'Phone Number',
            valueType: 'mobile',
            maxLength: 10,
        },
        killGroupName: {
            key: 'name',
            label: 'Kill Group Name',
            placeholder: 'Enter Kill Group Name',
            valueType: 'string',
            maxLength: 50,
        },
        payeeName: {
            key: 'name',
            label: 'Payee Name',
            placeholder: 'Enter Payee Name',
            valueType: 'string',
            maxLength: 50,
        },
    },
    isActive: 'isActive',
    cancel: 'Cancel',
    create: 'Create',
    update: 'Update',
    activate: 'Activate',
    initialState: {
        buyerDetail: {
            name: '',
            contactNumber: '',
        },
        buyerError: {
            name: false,
            contactNumber: false,
        },
        killGroupDetail: {
            name: '',
            isActive: true,
        },
        killGroupError: {
            name: false,
        },
        payeeDetail: {
            name: '',
        },
        payeeError: {
            name: false,
        },
    },
    countries: [
        {
            name: 'Select Country',
            value: '',
        },
        {
            name: 'US',
            value: 'US',
        },
        {
            name: 'Canada',
            value: 'canada',
        },
    ],
};

export const BuyPoOrderConstants = {
    title: 'Orders',
    terms: 'Terms',
    step2: 'Step 2',
    edit: 'Edit',
    fieldsWithWeightAndCosts: [
        'headCount',
        'estimatedHeadCount',
        'grossWeight',
        'estimatedGrossWeightPerHd',
        'totalDollars',
        'feeAmount',
    ],
    labels: {
        order: 'Order',
        totalHeaders: {
            total: 'Totals',
            totalHeadCount: 'Total Hd',
            totalEstimatedHdCount: 'Total Est Hd',
            totalGrossWeight: 'Total Gross Wt',
            totalEstimatedGrossWeightPerHd: 'Total Avg GW/Hd',
            totalPayWeight: 'Total Pay Wt',
            totalDollars: 'Total $ Amount',
            notes: 'Notes',
        },
        mobileLength: 'mobileLength',
        mobile: 'mobile',
        desktop: 'desktop',
    },
    form: {
        orderId: {
            name: 'orderId',
        },
        order: {
            label: 'Order #',
            name: 'orderName',
            maxLength: 15,
        },
        notes: {
            label: 'Notes',
            name: 'notes',
            placeholder: 'Enter Notes',
            maxLength: 100,
        },
        hdCount: {
            label: 'Hd',
            name: 'headCount',
            maxLength: 9,
        },
        lot: {
            label: 'Lot',
            name: 'lot',
            maxLength: 15,
        },
        grossWeight: {
            label: 'Gross Wt',
            name: 'grossWeight',
            maxLength: 10,
        },
        estimatedHdCount: {
            label: 'Est Hd',
            name: 'estimatedHeadCount',
            maxLength: 9,
        },
        estimatedGrossWeightPerHd: {
            label: 'Avg GW/Hd',
            name: 'estimatedGrossWeightPerHd',
            maxLength: 9,
        },
        payWeight: {
            label: 'Pay Wt',
            name: 'payWeight',
            maxLength: 10,
        },
        totalDollars: {
            label: '$ Amount',
            name: 'totalDollars',
            maxLength: 10,
        },
        owner: {
            label: 'Owner',
            name: 'owner',
            placeholder: 'Select Owner',
        },
        color: {
            headingLabel: {
                create: 'Create Color',
                update: 'Update Color',
            },
            label: 'Color',
            mobileLabel: 'Color',
            fieldName: 'color',
            idField: 'id',
            placeholder: 'Color',
            invalid: '',
            activateModalConstant: {
                heading: 'Activate Color',
                text: ' is currently Inactive. Activate and use this Color.',
            },
        },
        sex: {
            headingLabel: {
                create: 'Create Sex',
                update: 'Update Sex',
            },
            label: 'Sex',
            mobileLabel: 'Sex',
            fieldName: 'sex',
            idField: 'id',
            placeholder: 'Sex',
            invalid: '',
            activateModalConstant: {
                heading: 'Activate Sex',
                text: ' is currently Inactive. Activate and use this Sex.',
            },
        },
        breed: {
            headingLabel: {
                create: 'Create Breed',
                update: 'Update Breed',
            },
            label: 'Breed',
            mobileLabel: 'Breed',
            fieldName: 'breed',
            idField: 'id',
            placeholder: 'Breed',
            invalid: '',
            activateModalConstant: {
                heading: 'Activate Breed',
                text: ' is currently Inactive. Activate and use this Breed.',
            },
        },
        baseWeight: {
            label: 'Base Weight',
            name: 'baseWeight',
            maxLength: 10,
        },
        shrink: {
            label: 'Shrink %',
            name: 'shrink',
            maxLength: 5,
        },
        price: {
            label: 'Price',
            name: 'price',
            maxLength: 10,
        },
        slide: {
            label: 'Slide',
            name: 'slide',
            maxLength: 30,
        },
        slideUnit: {
            headingLabel: 'Create New Slide Unit',
            label: 'Slide Unit',
            mobileLabel: 'Slide Unit',
            fieldName: 'slideUnit',
            idField: 'slideUnitId',
            placeholder: 'Slide Unit',
            invalid: '',
        },
        slideDetail: {
            label: 'Slide Detail',
            name: 'slideDetail',
        },
    },
    initialState: {
        colorDetail: {
            name: '',
            isActive: true,
        },
        colorError: {
            name: false,
        },
        sexDetail: {
            name: '',
            isActive: true,
        },
        sexError: {
            name: false,
        },
        breedDetail: {
            name: '',
            isActive: true,
        },
        breedError: {
            name: false,
        },
        slideUnitDetail: {
            name: '',
        },
        slideUnitError: {
            name: false,
        },
    },
    dynamicFields: {
        colorName: {
            key: 'name',
            label: 'Color Name',
            placeholder: 'Enter Color Name',
            valueType: 'string',
            maxLength: 50,
        },
        sexName: {
            key: 'name',
            label: 'Sex Name',
            placeholder: 'Enter Sex Name',
            valueType: 'string',
            maxLength: 50,
        },
        breedName: {
            key: 'name',
            label: 'Breed Name',
            placeholder: 'Enter Breed Name',
            valueType: 'string',
            maxLength: 50,
        },
        slideUnitName: {
            key: 'name',
            label: 'Slide Unit Name',
            placeholder: 'Enter Slide Unit Name',
            valueType: 'string',
            maxLength: 50,
        },
    },
};

export const BuyPoOrderNotesConstants = {
    title: 'Order Notes',
};

export const BuyPoFeesConstants = {
    labels: {
        step3: 'Step 3',
        additionalFees: 'Additional Fees',
        newFee: 'New Fee',
        add: 'Add',
        total: {
            additionalFeesTotal: 'Additional Fees Total',
            grandTotal: 'Grand Total',
        },
    },
    form: {
        feeType: {
            label: 'Additional Fee Type',
            mobileLabel: 'Fee Type',
            name: 'feeType',
        },
        amount: {
            label: 'Amount',
            name: 'feeAmount',
            maxLength: 10,
        },
        notes: {
            label: 'Notes',
            name: 'notes',
        },
    },
    initialState: {
        initialFee: { feeId: 0, feeType: 'Freight', feeAmount: '', notes: '' },
        additionalFee: { feeId: 0, feeType: '', feeAmount: '', notes: '' },
    },
};

export const BuyPOListConstants = {
    title: 'Buy POs',
    limit: 20,
    offset: 0,
    filterBy: 'Filter By',
    name: 'name',
    id: 'id',
    submit: {
        success: 'Successfully Submitted!',
    },
    /* eslint-disable no-restricted-syntax */
    selectedPurchaseDateRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    },
    confirmationMessage: {
        deleteSingle: 'Do you want to delete this BuyPO : ',
        deleteMultiple: 'Do you want to delete selected BuyPOs Id : ',
    },
    labels: {
        searchAndFilters: 'Search and Filters',
        buyPO: 'Buy PO',
        addBuyPO: 'Add Buy PO',
        schedulePickUps: 'Scheduled Pick Ups',
        showCompleted: 'Show completed',
        noDataFound: 'No data found',
        cattlePaid: 'Cattle Paid',
        freightPaid: 'Freight Paid',
        cattleReceived: 'Cattle Received',
    },
    filterInitialState: {
        buyPOId: '',
        purchaseFromDate: '',
        purchaseToDate: '',
        buyLocation: '',
        hdCount: '',
        estimatedHeadCount: '',
        destination: '',
        payee: '',
        truckingCompany: '',
        isCattlePaid: '',
        isFreightPaid: '',
        isCattleReceived: '',
        limit: 20,
        offset: 0,
        show: 'incomplete',
    } as IBuyPoFilter,
    resetFilterState: {
        buyPOId: '',
        purchaseFromDate: '',
        purchaseToDate: '',
        buyLocation: '',
        hdCount: '',
        estimatedHeadCount: '',
        destination: '',
        payee: '',
        truckingCompany: '',
        killGroup: '',
        isCattlePaid: '',
        isFreightPaid: '',
        isCattleReceived: '',
        limit: 20,
        offset: 0,
        show: 'incomplete',
    } as IBuyPoFilter,
    filter: {
        buyPO: {
            label: 'Buy PO',
            name: 'buyPOId',
            maxLength: 9,
            placeholder: 'Search',
        },
        purchaseDate: {
            label: 'Purchase Date',
            placeholder: 'Select Date Range',
        },
        buyLocation: {
            label: 'Buy Location',
            name: 'buyLocation',
            maxLength: 50,
            placeholder: 'Search',
        },
        headCount: {
            label: 'Hd Ct',
            name: 'hdCount',
            maxLength: 9,
            placeholder: 'Search',
        },
        estimatedHeadCount: {
            label: 'Est Hd',
            name: 'estimatedHeadCount',
            maxLength: 9,
            placeholder: 'Search',
        },
        destination: {
            label: 'Destination',
            name: 'destination',
            maxLength: 50,
            placeholder: 'Search',
        },
        payee: {
            label: 'Payee',
            name: 'payee',
            maxLength: 50,
            placeholder: 'Search',
        },
        truckingCompany: {
            label: 'Trucking Company',
            name: 'truckingCompany',
            maxLength: 50,
            placeholder: 'Search',
        },
        killGroup: {
            label: 'Kill Group',
            name: 'killGroup',
            maxLength: 50,
            placeholder: 'Search',
        },
        showComplete: {
            label: 'Completed',
            name: 'show',
            value: 'complete',
        },
        showAll: {
            label: 'All',
            name: 'show',
            value: 'all',
        },
        showInComplete: {
            label: 'Incomplete',
            name: 'show',
            value: 'incomplete',
        },
        clearFilter: {
            label: 'Clear Filters',
        },
        apply: {
            label: 'Apply',
        },
        active: {
            label: 'active',
        },
    },
    headers: {
        buyPO: {
            label: 'Buy PO',
        },
        purchaseDate: {
            label: 'Purchase Date',
        },
        location: {
            label: 'Buy Location',
        },
        headCount: {
            label: 'Hd Ct',
        },
        estimatedHeadCount: {
            label: 'Est Hd',
        },
        destination: {
            label: 'Destination',
        },
        payee: {
            label: 'Payee',
        },
        truckingCompany: {
            label: 'Trucking Company',
        },
        killGroup: {
            label: 'Kill Group',
        },
        cattlePaid: {
            label: 'Cattle Paid',
            fieldName: 'isCattlePaid',
        },
        freightPaid: {
            label: 'Freight Paid',
            fieldName: 'isFreightPaid',
        },
        cattleReceived: {
            label: 'Cattle Received',
            fieldName: 'isCattleReceived',
        },
    },
    LOCATIONS: [
        {
            name: LabelConstant.Location.CREATE_LOCATION,
            id: LabelConstant.Location.CREATE_LOCATION,
        },
    ],
    DESTINATIONS: [
        {
            name: LabelConstant.Destination.CREATE_DESTINATION,
            id: LabelConstant.Destination.CREATE_DESTINATION,
        },
    ],
    TRUCKING_COMPANIES: [
        {
            name: LabelConstant.TruckingCompany.CREATE_COMPANY,
            companyId: LabelConstant.TruckingCompany.CREATE_COMPANY,
        },
    ],
    COMPANY_OWNERS: [
        {
            name: LabelConstant.Owner.CREATE_OWNER,
            id: LabelConstant.Owner.CREATE_OWNER,
        },
    ],
    COLOR: [
        {
            name: LabelConstant.Color.CREATE_COLOR,
            id: LabelConstant.Color.CREATE_COLOR,
        },
    ],
    SEX: [
        {
            name: LabelConstant.Sex.CREATE_SEX,
            id: LabelConstant.Sex.CREATE_SEX,
        },
    ],
    BREED: [
        {
            name: LabelConstant.Breed.CREATE_BREED,
            id: LabelConstant.Breed.CREATE_BREED,
        },
    ],
    TERM_SLIDE_UNIT: [
        {
            name: LabelConstant.SlideUnit.CREATE_SLIDE_UNIT,
            slideUnitId: LabelConstant.SlideUnit.CREATE_SLIDE_UNIT,
        },
    ],
    FEE_TYPES: [
        { name: LabelConstant.FeeType.SELECT_FEE_TYPE, value: '' },
        {
            name: LabelConstant.FeeType.TYPE_COMMISSION,
            value: LabelConstant.FeeType.TYPE_COMMISSION,
        },
        {
            name: LabelConstant.FeeType.TYPE_FREIGHT,
            value: LabelConstant.FeeType.TYPE_FREIGHT,
        },
        {
            name: LabelConstant.FeeType.TYPE_MISCELLANEOUS,
            value: LabelConstant.FeeType.TYPE_MISCELLANEOUS,
        },
    ],
    BUYERS: [
        {
            name: LabelConstant.Buyer.CREATE_BUYER,
            buyerId: LabelConstant.Buyer.CREATE_BUYER,
        },
    ],
    SECOND_BUYERS: [
        {
            name: LabelConstant.SecondBuyer.CREATE_BUYER,
            buyerId: LabelConstant.SecondBuyer.CREATE_BUYER,
        },
    ],
    KILL_GROUP: [
        {
            name: LabelConstant.KillGroup.CREATE_KILL_GROUP,
            killGroupId: LabelConstant.KillGroup.CREATE_KILL_GROUP,
        },
    ],
    PAYEE: [
        {
            name: LabelConstant.Payee.CREATE_PAYEE,
            id: LabelConstant.Payee.CREATE_PAYEE,
        },
    ],
};

export const DuplicateBuyPoNotificationModalConstants = {
    Heading: 'Similar Buy PO’s exist that might be duplicates.',
    textMessage: 'Do you want to proceed and submit your similar BuyPO?',
};

export const DestinationModalConstants = {
    contactCountryCode: '+1',
    canadaState: 'canada',
    header: {
        create: 'Create New Destination',
        update: 'Update Destination',
    },
    form: {
        name: { name: 'name', label: 'Location Name', maxLength: 50 },
        address1: { name: 'address1', label: 'Address Line 1', maxLength: 50 },
        address2: { name: 'address2', label: 'Address Line 2', maxLength: 50 },
        gpsCoordinates: {
            name: 'coordinates',
            label: 'GPS Coordinates',
            maxLength: 50,
            placeholder: 'EX:+00.000,-00.000',
        },
        country: { label: 'Country', name: 'country' },
        state: {
            name: 'state',
            label: 'State',
            provinceLabel: 'Province/Territory',
        },
        zipCode: {
            name: 'postalCode',
            label: 'Zip Code',
            maxLength: 10,
            placeholder: '98786 or 98768-9876',
            canadaPlaceholder: 'A9A 9A9',
        },
        city: { name: 'city', label: 'City', maxLength: 50 },
        manager1: {
            name: 'contactName',
            label: 'Manager Name 1',
            maxLength: 50,
            valueType: 'string',
        },
        email1: {
            name: 'email',
            label: 'Email 1',
            maxLength: 50,
        },
        email2: {
            name: 'altEmail',
            label: 'Email 2',
            maxLength: 50,
        },
        phoneNo1: {
            name: 'contactNumber',
            label: 'Phone Number 1',
            maxLength: 10,
            valueType: 'mobile',
        },
        phoneNo2: {
            name: 'altContactNumber',
            label: 'Phone Number 2',
            maxLength: 10,
            valueType: 'mobile',
        },
    },
    footer: {
        cancel: 'Cancel',
        create: 'Create',
        update: 'Update',
    },
    errorMessageArray: [
        'Invalid Postal Code!',
        'Invalid Coordinates!',
        'Invalid Email!',
        'Invalid Alternative Email!',
        'Invalid Phone Number!',
        'Invalid Alternative Phone Number!',
    ],
    validateFields: [
        'postalCode',
        'coordinates',
        'email',
        'altEmail',
        'contactNumber',
        'altContactNumber',
    ],
    validateTypes: [
        'zipcode',
        'coordinates',
        'email',
        'email',
        'mobileLength',
        'mobileLength',
    ],
    initialState: {
        destinationDetail: {
            name: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            country: '',
            postalCode: '',
            coordinates: '',
            email: '',
            altEmail: '',
            contactNumber: '',
            altContactNumber: '',
            contactName: '',
            isActive: true,
        },
        destinationError: {
            name: false,
            address1: false,
            city: false,
            postalCode: false,
            coordinates: false,
        },
    },
    requiredAddressDetails: ['address1', 'city', 'state', 'postalCode'],
    requiredFields: {
        address1: 'address1',
        city: 'city',
        postalCode: 'postalCode',
        coordinates: 'coordinates',
    },
};

export const LocationModalConstants = {
    canadaState: 'canada',
    header: {
        create: 'Create New Buy Location',
        shipCreate: 'Create New Ship Location',
        update: 'Update Buy Location',
    },
    form: {
        name: { name: 'name', label: 'Location Name', maxLength: 50 },
        address1: { name: 'address1', label: 'Address Line 1', maxLength: 50 },
        address2: { name: 'address2', label: 'Address Line 2', maxLength: 50 },
        gpsCoordinates: {
            name: 'coordinates',
            label: 'GPS Coordinates',
            maxLength: 50,
            placeholder: 'EX:+00.000,-00.000',
        },
        country: { label: 'Country', name: 'country' },
        state: {
            name: 'state',
            label: 'State',
            provinceLabel: 'Province/Territory',
        },
        zipCode: {
            name: 'postalCode',
            label: 'Zip Code',
            maxLength: 10,
            placeholder: '98786 or 98768-9876',
            canadaPlaceholder: 'A9A 9A9',
        },
        city: { name: 'city', label: 'City', maxLength: 50 },
    },
    footer: {
        cancel: 'Cancel',
        create: 'Create',
        update: 'Update',
    },
    errorMessageArray: ['Invalid Postal Code!', 'Invalid Coordinates!'],
    initialState: {
        locationDetail: {
            name: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            country: '',
            postalCode: '',
            coordinates: '',
            isActive: true,
        },
        locationError: {
            name: false,
        },
    },
};

export const buyPoExcelConstants = {
    fileName: 'BuyPo Record History',
    labels: {
        exportToExcel: 'Export to Excel',
    },
    buyPoDetails: [
        {
            purchaseOrderID: 'Purchase Order ID',
            purchaseDate: 'PurchaseDate',
            orderName: 'OrderName',
            hdCount: 'HdCount',
            payWeight: 'PayWeight',
            averageWeight: 'AverageWeight',
            costofCattle: 'Cost of Cattle',
            costPerLb: 'CostPerLb',
            buyLocation: 'BuyLocation',
            destination: 'Destination',
            payee: 'Payee',
            ownerName: 'OwnerName',
            truckingCompany: 'TruckingCompany',
            killGroup: 'KillGroup',
            notes: 'Notes',
        },
    ],
};
