import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import StorageService from '../../../../../../Services/storage/storage.service';
import { FeedCallConstants } from '../../../../../../utils/constants/FeedCallConstants';
import Constants from '../../../../../../utils/Constants';
import { feedCallPageSliceInitialState } from './feedCallPageSliceInitialState';
import {
    FeedCallGraphDays,
    FeedCallTableDays,
    IFeedCallPageSliceInitialState,
    ILocalAssessmentValues,
} from './interfaces';
import {
    FeedlotManagerModelsFeedFeedCallFeedCallAssessmentModel,
    FeedlotManagerModelsFeedFeedCallFeedCallResponseModel,
} from '../../../../../../Redux/Apis/FeedCall/baseFeedCallApi';
import { setLocalAssessmentValues } from '../helpers';
import { commonActions } from '../../../../../../Redux/Reducer/common';
import { IFeedCallPageSelectedPenAndRationFilter } from '../interfaces';
import { StorageKeys } from '../../../../../../Services/storage/storageKeys';

const feedCallPageSlice = createSlice({
    name: 'feedCallPage',
    initialState: feedCallPageSliceInitialState,
    reducers: {
        setSelectedPenId: (
            state,
            action: PayloadAction<number | undefined>,
        ) => {
            const penId = action.payload;
            const value: IFeedCallPageSelectedPenAndRationFilter = {
                penId,
                penFilterByRation: state.penFilterByRation,
            };
            new StorageService().setValue(
                StorageKeys.FeedCallPageSelectedPenAndRationFilter,
                value,
                Constants.Storage.LOCAL,
            );

            if (state.selectedPenId !== penId) {
                const newState: IFeedCallPageSliceInitialState = {
                    ...feedCallPageSliceInitialState,
                    selectedPenId: penId,
                    penFilterByRation: state.penFilterByRation,
                    //IRFTODO add other pieces we don't want to reset on pen change
                };
                Object.assign(state, newState);
            }
        },
        setPenFilterByRation: (
            state,
            action: PayloadAction<string | number>,
        ) => {
            const penFilterByRation = action.payload;
            const value: IFeedCallPageSelectedPenAndRationFilter = {
                penId: state.selectedPenId,
                penFilterByRation,
            };
            new StorageService().setValue(
                StorageKeys.FeedCallPageSelectedPenAndRationFilter,
                value,
                Constants.Storage.LOCAL,
            );
            state.penFilterByRation = penFilterByRation;
        },
        setShowRationChangeReminderModal: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.showRationChangeReminderModal = action.payload;
        },
        setShowAFPerHeadLimitModal: (state, action: PayloadAction<boolean>) => {
            state.showAFPerHeadLimitModal = action.payload;
        },
        setShowPenRationScheduleModal: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.showPenRationScheduleModal = action.payload;
        },
        setIsAFPerHeadLimitModalConfirmedForPen: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.isAFPerHeadLimitModalConfirmedForPen = action.payload;
        },
        setLocalAssessmentValues: (
            state,
            action: PayloadAction<Partial<ILocalAssessmentValues>>,
        ) => {
            for (const key in state.localAssessmentValues) {
                if (Object.hasOwn(action.payload, key)) {
                    state.localAssessmentValues[key] = action.payload[key];
                }
            }
        },
        setDaysOfHistoricalTableData: (
            state,
            action: PayloadAction<FeedCallTableDays>,
        ) => {
            state.daysOfHistoricalTableData = action.payload;
        },
        setDaysOfHistoricalGraphData: (
            state,
            action: PayloadAction<FeedCallGraphDays>,
        ) => {
            const newGraphDays = action.payload;
            const tableDays = state.daysOfHistoricalTableData;
            //clean this logic up. Idea is if we get 30 or 60 days of graph data, show that on the table as well since we get the data for free.
            if (
                tableDays !== 'all' &&
                tableDays.slice(4) < newGraphDays.slice(4)
            ) {
                state.daysOfHistoricalTableData = newGraphDays;
            }

            new StorageService().setValue(
                FeedCallConstants.selectedGraphDays,
                newGraphDays,
                Constants.Storage.LOCAL,
            );
            state.daysOfHistoricalGraphData = newGraphDays;
        },
        setAssessmentPendingConfirmation: (
            state,
            action: PayloadAction<FeedlotManagerModelsFeedFeedCallFeedCallAssessmentModel | null>,
        ) => {
            state.assessmentPendingConfirmation = action.payload;
        },
        setAssessmentInFeedCallPayloadAndLocalAssessment: (
            state,
            action: PayloadAction<FeedlotManagerModelsFeedFeedCallFeedCallAssessmentModel>,
        ) => {
            state.feedCallPayload = {
                ...state.feedCallPayload,
                ...action.payload,
                hdCount:
                    action.payload.hdCount ?? state.feedCallPayload.hdCount,
            };
            setLocalAssessmentValues(state, action.payload);
        },
        setFeedCallPayloadAndLocalAssessmentValuesFromInitialFeedCallLoad: (
            state,
            action: PayloadAction<FeedlotManagerModelsFeedFeedCallFeedCallResponseModel>,
        ) => {
            state.feedCallPayload = action.payload;

            setLocalAssessmentValues(state, action.payload);
        },
        setBunkScore: (state, action: PayloadAction<number>) => {
            state.feedCallPayload.bunkScore = action.payload;
        },
        setComments: (state, action: PayloadAction<string>) => {
            state.feedCallPayload.comments = action.payload;
        },
        setLocalAssessmentValuesToValuesInFeedCallPayload: state => {
            setLocalAssessmentValues(state, state.feedCallPayload);
        },
        //IRFNOTE this loading state is necesssary because it bookends the entire assessment process.
        //just waiting for assessment api to return is not sufficient - we need to allow data to propogate through redux
        //by dispatching a setLoading(false) at the end of the assessment process, we guarantee that the values set by the assessment process will be set when loading=false change is received by components. At this point it is safe to initiate a save.
        setAssessmentProcessing: (state, action: PayloadAction<boolean>) => {
            state.isAssessmentProcessing = action.payload;
        },
        setShouldCallSaveAndNextPen: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.shouldCallSaveAndNextPen = action.payload;
        },
        setShouldFocusBunkScoreDropdown: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.shouldFocusBunkScoreDropdown = action.payload;
        },
        setErrorModalMessage: (state, action: PayloadAction<string>) => {
            state.errorModalMessage = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(commonActions.setSelectedFeedlot, state => {
            state = feedCallPageSliceInitialState;
        });
    },
});

export const feedCallPageActions = feedCallPageSlice.actions;
export const feedCallPageReducer = feedCallPageSlice.reducer;
