import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TENANT_SETTINGS_INITIAL_STATE } from '../constants/TenantSettingsInitialState';
import {
    ISettingsState,
    IvisibilitySetting,
} from '../interface/SettingsInterface';

const tenantSettingsSlice = createSlice({
    name: 'tenantSettings',
    initialState: TENANT_SETTINGS_INITIAL_STATE,
    reducers: {
        setKillGroupVisibility: (state, action: PayloadAction<boolean>) => {
            state.canAccessKillGroup = action.payload;
        },
        setSettingVisibility: (
            state,
            action: PayloadAction<{
                settingType: string;
                payload: IvisibilitySetting;
            }>,
        ) => {
            const { settingType, payload } = action.payload;
            state.SettingVisibility[settingType] = {
                ...state.SettingVisibility[settingType],
                ...payload,
            };
        },
        setAllSettingVisibility: (
            state,
            action: PayloadAction<IvisibilitySetting[]>,
        ) => {
            action.payload.forEach(setting => {
                const key =
                    setting.settingName as keyof ISettingsState['SettingVisibility'];
                if (key in state.SettingVisibility) {
                    state.SettingVisibility[key] = {
                        ...state.SettingVisibility[key],
                        ...setting,
                    };
                }
            });
        },
        setTenantSettingsFilter: (state, action) => {
            state.filter = action.payload;
        },
        appendTenantSettingsFilter: (state, action) => {
            state.filter = { ...state.filter, ...action.payload };
        },
        setTenantSettingsLoader: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const {
    setKillGroupVisibility,
    setSettingVisibility,
    setAllSettingVisibility,
    setTenantSettingsFilter,
    appendTenantSettingsFilter,
    setTenantSettingsLoader,
} = tenantSettingsSlice.actions;

const tenantSettingReducer = tenantSettingsSlice.reducer;
export default tenantSettingReducer;
