import { useState, useEffect, ReactElement } from 'react';

import SideNavTab from './sidenav/SideNavTab';
import SideNavBuyPO from './sidenav/SideNavBuyPO';
import SideNavFM from './sidenav/SideNavFM';
import SideNavFinancial from './sidenav/SideNavFinancial';
import SidebarLogoBuyPo from '../Assets/sidebarlogos/SidebarLogoBuyPo';
import SidebarLogoFinancial from '../Assets/sidebarlogos/SidebarLogoFinancial';
import SidebarLogoNGAT from '../Assets/sidebarlogos/SidebarLogoNGAT';
import SidebarLogoFM from '../Assets/sidebarlogos/SidebarLogoFM';
import useAuthContext from '../apps/common/modules/auth/hooks/useAuthContext';
import { useAppDispatch, useAppSelector } from '../Redux/Store';
import {
    IGenericSetState,
    sideNavTabEnum,
} from '../utils/Interface/CommonInterface';
import { useLocation } from 'react-router-dom';
import { setActive, setSelectedSideNavTab } from '../Redux/Reducer/common';
import { feedlotManagerFeatures } from '../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import Constants from '../utils/Constants';

const { isCA10935On_TreatmentRevamp } = feedlotManagerFeatures;

function SideNav({
    sideNavTab,
    setSideNavTab,
}: {
    sideNavTab: string | number;
    setSideNavTab: IGenericSetState<string | number>;
}) {
    const { isAuthenticated } = useAuthContext();
    const [sideNavLogoComponent, setSideNavLogoComponent] =
        useState<ReactElement>();
    const [sideNavMenuComponent, setSideNavMenuComponent] =
        useState<ReactElement>();

    const { isNavActive } = useAppSelector(state => state.common);

    const dispatch = useAppDispatch();

    const handleClick = event => {
        isNavActive ? dispatch(setActive(false)) : dispatch(setActive(true));
    };
    const location = useLocation();

    useEffect(() => {
        if (
            location.pathname === '/feedcall' ||
            location.pathname === '/feedcallv2' ||
            location.pathname ===
                Constants.Routes.CREATE_INBOUND_SCALE_TICKETS ||
            location.pathname ===
                Constants.Routes.CREATE_OUTGOING_SCALE_TICKETS ||
            (!isCA10935On_TreatmentRevamp &&
                (location.pathname === '/treatments/treatmentform' ||
                    location.pathname.includes('/treatments/updatetreatment/')))
        ) {
            dispatch(setActive(true));
        }
    }, [dispatch, location.pathname]);

    useEffect(() => {
        const getSideNavLogoAndMenu = () => {
            let logoComponent = <SidebarLogoNGAT />;
            let menuComponent = <></>;
            if (sideNavTab === 'buyPo') {
                dispatch(setSelectedSideNavTab(sideNavTabEnum.BuyPo));
                logoComponent = <SidebarLogoBuyPo />;
                menuComponent = <SideNavBuyPO />;
            } else if (sideNavTab === 'financial') {
                dispatch(setSelectedSideNavTab(sideNavTabEnum.Financial));
                logoComponent = <SidebarLogoFinancial />;
                menuComponent = <SideNavFinancial />;
            } else if (sideNavTab === 'fm') {
                dispatch(setSelectedSideNavTab(sideNavTabEnum.Fm));
                logoComponent = <SidebarLogoFM />;
                menuComponent = <SideNavFM />;
            }
            setSideNavLogoComponent(logoComponent);
            setSideNavMenuComponent(menuComponent);
        };
        getSideNavLogoAndMenu();
    }, [sideNavTab]);

    return (
        <>
            {isAuthenticated() ? (
                <>
                    <nav
                        className={`d-lg-active sidenav${
                            isNavActive ? ' sidebar-shrink' : ''
                        }`}
                    >
                        <div className="sidebar-logo">
                            {sideNavLogoComponent}
                        </div>
                        <SideNavTab setSideNavTab={setSideNavTab} />
                        {sideNavMenuComponent}
                    </nav>

                    <div
                        className={
                            isNavActive
                                ? 'sidenav-caret caret-slide-left d-lg-block d-none'
                                : 'sidenav-caret d-lg-block d-none'
                        }
                        onClick={handleClick}
                    >
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a>
                            <i className="fas fa-caret-left"></i>
                        </a>
                    </div>
                </>
            ) : null}
        </>
    );
}

export default SideNav;
