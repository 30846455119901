import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../Store';

const url = process.env.REACT_APP_API_URL;
const rtkBaseUrl = url?.slice(0, url?.lastIndexOf('/api'));

//docs: https://redux-toolkit.js.org/rtk-query/usage/code-splitting
export const rootApi = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: rtkBaseUrl,
        timeout: 30000,
        credentials: 'include',
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.accessToken;
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    endpoints: () => ({}),
    //custom tag types and tag types used outside of the baseApi they are generated in must be included here
    tagTypes: ['FeedCall', 'PenRationSchedule', 'EstimatedHead'],
});

export interface BaseOdataQueryApiArg {
    filter?: string;
    orderBy?: string;
    top?: number;
    skip?: number;
}
