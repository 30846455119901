import { getFeatureFlagValue } from './helpers';
import { ICommonFeatures } from './interface';

export const commonFeatures: ICommonFeatures = {
    isCA9685_localAuthLoginScreen: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9685_LOCAL_AUTH_LOGIN_SCREEN,
    ),
    isCA10248_i18nBug: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10248_I18N_BUG,
    ),
    isCA11388On_TermsOfServiceLoginScreen: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11388_TERMS_OF_SERVICE_LOGIN_SCREEN,
    ),
    isCA10347_useAppLoaderHookOn: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10347_ADD_USE_APP_LOADER_HOOK,
    ),
};
