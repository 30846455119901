import { ICattlePOFeatures } from './interface';
import { getFeatureFlagValue } from '../../common/featureFlags/helpers';

export const cattlePO: ICattlePOFeatures = {
    isBP860On_buyPOPickupDateRange: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP860_BUYPO_PICKUP_DATE_RANGE,
    ),
    isBP914On_tenantSettingColor: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP914_TENANT_SETTING_COLOR,
    ),
    isBP915On_tenantSettingSex: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP915_TENANT_SETTING_SEX,
    ),
    isBP913On_tenantSettingBreed: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP913_TENANT_SETTING_BREED,
    ),
    isBP961_buyPOSlideDetail: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP961_BUYPO_SLIDE_DETAIL,
    ),
    isBP925_buyPOBuyer2: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP925_BUYPO_BUYER2,
    ),
    isBP865_buypoDoaField: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP865_BUYPO_DOA_FIELD,
    ),
    isBP1038_mobileScreenPageNotFoundIssue: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP1038_MOBILE_SCREEN_PAGE_NOT_ISSUE,
    ),
    isBP933_holdPaymentCheckboxBuyerForm: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP933_HOLD_PAYMENT_CHECKBOX_BUYER_FORM,
    ),
    isBP928_buyPOCutField: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP865_BUYPO_CUT_FIELD,
    ),
    isBP934_BuyPOHoldPaymentCommentBox: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_BP934_BUYPO_HOLD_PAYMENT_COMMENTBOX,
    ),
};
