import { currentDate_deprecated } from '../../helpers';
import { ICsvData } from '../../utils/Interface/CommonInterface';
import { LotState } from '../../utils/Interface/LotInterface';
import {
    IShipCattleLot,
    IShipCattleLotData,
    IShipCattlePenDetail,
    IShipCattleSummaryAndCostTotals,
} from '../../utils/Interface/ShipCattleInterface';
import {
    LabelConstant,
    shipCattleLabelConstants,
} from '../../utils/LabelConstant';

export const CREATE_SHIP_CATTLE_INITIAL_STATE = {
    shipCattleBasicDetail: {
        id: '',
        date: currentDate_deprecated('YYYY-MM-DD'),
        destination: 0,
        destinationName: '',
        notes: '',
    },
    shipCattleBasicDetailError: { date: '', destination: '' },
    pens: [] as IShipCattlePenDetail[],
    setPens: [],
    locations: [],
    lots: [
        {
            lotId: 0,
            name: '',
            ownerId: '',
            cattleTypeId: '',
            sexId: '',
        },
    ] as IShipCattleLotData[],
    shipCattleLot: {
        shipCattleLotId: 0,
        shipCattleLotName: '',
        currentHeadCount: 0,
        lotTotalHeadToShip: '',
        backdatedHeadCount: 0,
        lotState: LotState.Open,
    } as IShipCattleLot,
    generatedInvoiceCutoffDate: '',
    firstAllowedDateBasedOnCutoff: '',
    shipCattleLotError: { lotTotalHeadToShip: '', shipCattleLotId: '' },
    shipCattleLoadTickets: [
        {
            loadTicketId: '',
            scaleTicketNumber: '',
            grossWeight: '',
            headCount: '',
            shrinkPercent: '',
        },
    ],
    shipCattleLoadTicketsError: [
        {
            scaleTicketNumber: '',
            grossWeight: '',
            headCount: '',
            shrinkPercent: '',
        },
    ],
    shipCattleFeeError: { costs: '', amount: '' },
    costFeeTotalAmount: 0,
    loadTicket: {
        loadTicketId: '',
        scaleTicketNumber: '',
        grossWeight: '',
        headCount: '',
        shrinkPercent: '',
    },
    loadTicketError: {
        scaleTicketNumber: '',
        grossWeight: '',
        headCount: '',
        shrinkPercent: '',
    },
    shipCattleFee: {
        tableId: '',
        tableName: 'ShipCattle',
        id: '',
        costs: '',
        amount: '',
        notes: '',
        isDisable: false,
    },
    shipCattleSummaryAndCostTotals: {
        headCountTotal: 0,
        grossOutweight: 0,
        payWeight: 0.0,
        averageShrinkPercent: 0,
        loadTicketHdCountTotal: 0,
    } as IShipCattleSummaryAndCostTotals,
    loadTicketsHdCountTotal: 0,
    shipCattleFees: [
        {
            tableId: '',
            tableName: 'ShipCattle',
            id: '',
            costs: 'Cattle',
            amount: '',
            notes: '',
            isDisable: true,
        },
        {
            tableId: '',
            tableName: 'ShipCattle',
            id: '',
            costs: 'Freight',
            amount: '',
            notes: '',
            isDisable: true,
        },
        {
            tableId: '',
            tableName: 'ShipCattle',
            id: '',
            costs: 'Brands',
            amount: '',
            notes: '',
            isDisable: true,
        },
    ],
    shipCattleFeesError: [
        { costs: '', amount: '' },
        { costs: '', amount: '' },
        { costs: '', amount: '' },
    ],
    shipCattleFeesInitialState: [
        {
            tableId: '',
            tableName: 'ShipCattle',
            id: '',
            costs: 'Cattle',
            amount: '',
            notes: '',
            isDisable: true,
        },
        {
            tableId: '',
            tableName: 'ShipCattle',
            id: '',
            costs: 'Freight',
            amount: '',
            notes: '',
            isDisable: true,
        },
        {
            tableId: '',
            tableName: 'ShipCattle',
            id: '',
            costs: 'Brands',
            amount: '',
            notes: '',
            isDisable: true,
        },
    ],
    FEE_TYPES: [
        {
            name: LabelConstant.FeeType.SELECT_FEE_TYPE,
            value: LabelConstant.FeeType.SELECT_FEE_TYPE,
        },
        {
            name: LabelConstant.FeeType.TYPE_BRAND,
            value: LabelConstant.FeeType.TYPE_BRAND,
        },
        {
            name: LabelConstant.FeeType.TYPE_COMMISSION,
            value: LabelConstant.FeeType.TYPE_COMMISSION,
        },
        {
            name: LabelConstant.FeeType.TYPE_FREIGHT,
            value: LabelConstant.FeeType.TYPE_FREIGHT,
        },
        {
            name: LabelConstant.FeeType.TYPE_CVI,
            value: LabelConstant.FeeType.TYPE_CVI,
        },
        {
            name: LabelConstant.FeeType.TYPE_MISCELLANEOUS,
            value: LabelConstant.FeeType.TYPE_MISCELLANEOUS,
        },
    ],
    FEE_TYPES_FEEDLOT: [
        {
            name: LabelConstant.FeeType.TYPE_BRAND,
            value: LabelConstant.FeeType.TYPE_BRAND,
        },
    ],
    shipCattleFeedLotFees: [
        {
            tableId: '',
            tableName: 'ShipCattle',
            id: '',
            costs: LabelConstant.FeeType.TYPE_BRAND,
            amount: '',
            notes: '',
            isDisable: true,
        },
    ],
    GET_DESTINATION: [
        {
            name: shipCattleLabelConstants.BuyLocation.CREATE_LOCATION,
            id: shipCattleLabelConstants.BuyLocation.CREATE_LOCATION,
        },
    ],
    loading: false,
    shipCattleReports: [] as ICsvData[],
    submitPending: false,
};
