export const CommonConstants = {
    backdateIntoGeneratedInvoiceError: {
        headingText:
            'This record has a date that is within a generated billing period',
        updateErrorMessage: '. Please select a date after',
        deleteErrorMessage: ' and cannot be deleted.',
        bodyText:
            'To make adjustments to the invoice, please submit a miscellaneous charge or credit.',
    },
    eidInput: {
        inputLabel: 'EID',
    },
    dateRangeModal: {
        labels: {
            startDate: 'Start Date',
            endDate: 'End Date',
            cancelButton: 'Cancel',
            exportButton: 'Export to Excel',
        },
    },
    backdateCausesNegativeHdError: {
        en: {
            dateSelectedWillCauseNegativeHd:
                'The selected date will cause a negative headcount.',
            pleaseSelectDateAfter: 'Please select a date on or after ',
        },
        es: {
            dateSelectedWillCauseNegativeHd:
                'La fecha seleccionada causará un conteo de personal negativo.',
            pleaseSelectDateAfter:
                'Por favor, elija una fecha en o después de ',
        },
    },
    lotState: {
        Open: 'Open',
        Closing: 'Closing',
        Inactive: 'Inactive',
    },
    en: {
        total: 'Total',
        reset: 'Reset',
        name: 'name',
        id: 'id',
        deactivate: 'Deactivate',
        close: 'Close',
        no: 'No',
        yes: 'Yes',
        ok: 'Ok',
        addRow: 'Add Row',
        addTerms: 'Add Terms',
        unitOfWeight: 'lbs',
        dollarSign: '$',
        cancel: 'Cancel',
        create: 'Create',
        update: 'Update',
        save: 'Save',
        delete: 'Delete',
        submit: 'Submit',
        selectLot: 'Select Lot',
        selectDestination: 'Select Destination',
        selectRation: 'Select Ration',
        selectPen: 'Select Pen',
        selectOwner: 'Select Owner',
        selectBuyer: 'Select Buyer',
        selectBuyLocation: 'Select Buy Location',
        selectFeedType: 'Select Feed Type',
        selectDiagnosis: 'Select a Diagnosis',
        selectProcessingProtocol: 'Select Processing Protocol',
        noDataFound: 'No Data Found',
        selectedPenError:
            'Creating a record with the selected pen on the selected date will cause a negative hd count. Please select a different pen or date.',
        add: 'Add',
    },
    es: {
        cancel: 'Cancelar',
        create: 'Crear',
        close: 'Cerca',
        yes: 'Sí',
        no: 'No',
        selectedPenError:
            'Creando un registro con el corral seleccionado en la fecha seleccionada provocará un conteo de cabezas negativo. Por favor, selecciona un corral o fecha diferente.',
        add: 'Agregar',
        deactivate: 'Desactivar',
        submit: 'Enviar',
    },
    minusRegex: /^-$/,
    onlySpaceAllowedAlphanumericRegex: /^[a-zA-Z0-9][a-zA-Z0-9 ]*$/,
};
