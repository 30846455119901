import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFeedlotId } from '../../../../commonHooks/useFeedlotId';
import { useModalStatus } from '../../../../commonHooks/useModalStatus';
import {
    createMultiLineScaleTicketService,
    fetchCommodities,
    fetchContract,
    fetchTruckingCompanies,
    fetchVendor,
    setCreateScaleTicketDetails,
    setCreateScaleTicketDetailsError,
    setLoading,
} from '../actions/ScaleTicketActions';
import { SCALE_TICKET_INITIAL_STATE } from '../constants/scaleTicketsInitialState';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Store';
import Constants from '../../../../utils/Constants';
import {
    EditScaleTicketConstants,
    ScaleTicketConstants,
    scaleTicketLabelConstants,
} from '../constants/ScaleTicketConstants';
import {
    ICreateScaleTicketPayload,
    ScaleTicketContractStatus,
} from '../interfaces/ScaleTicketInterface';
import { validateAccordingType } from '../../../../utils/validation';
import ErrorModal from '../../../../components/common/ErrorModal';
import FullPageLoader from '../../../../components/common/FullPageLoader';
import CreateContractModal from './CreateContractModal';
import CreateVendorModal from './CreateVendorModal';
import CreateScaleTicketAdvanceDetails from './CreateScaleTicketAdvanceDetails';
import CreateScaleTicketBasicDetails from './CreateScaleTicketBasicDetails';
import EditScaleTicketFooter from './EditScaleTicketFooter';
import { feedlotManagerFeatures } from '../../../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { VENDOR_INITIAL_STATE } from '../../../../Redux/Constants/vendorInitialState';
import VendorModal from '../../../../components/pages/VendorModal';
import CreateScaleTicketAdvanceDetailsHeaders from './CreateScaleTicketAdvanceDetailsHeaders';
import { deepClone } from '../../../../helpers';

const { isCA9800On_ContractsMVP, isCA10570On_VendorList } =
    feedlotManagerFeatures;

const CreateScaleTicket = () => {
    const feedlotId = useFeedlotId();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const contractModal = useModalStatus();
    const vendorModal = useModalStatus();
    const [showError, setShowError] = useState({
        error: false,
        errorMessage: '',
        buttonText: '',
    });
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const { createScaleTicketDetails, loading, createScaleTicketDetailsError } =
        useAppSelector(state => state.scaleTicket);

    const scaleTicketDetailsErrorHandler = (
        fieldName: string,
        fieldName2?: string,
        index?: number,
    ) => {
        const errorStateCopy = deepClone(createScaleTicketDetailsError);

        if (
            fieldName === 'scaleTicketSegmentError' &&
            fieldName2 !== undefined &&
            index !== undefined
        ) {
            errorStateCopy.scaleTicketSegmentError =
                errorStateCopy.scaleTicketSegmentError.map((segment, i) =>
                    i === index ? { ...segment, [fieldName2]: false } : segment,
                );
        } else {
            errorStateCopy[fieldName] = false;
        }

        dispatch(setCreateScaleTicketDetailsError(errorStateCopy));
    };

    const getScaleTypeFromPathname = (): number => {
        const isInbound =
            location.pathname.includes(
                Constants.Routes.CREATE_INBOUND_SCALE_TICKETS,
            ) ||
            location.pathname.includes(
                Constants.Routes.UPDATE_INBOUND_SCALE_TICKETS,
            );
        return isInbound
            ? EditScaleTicketConstants.scaleType.inbound
            : EditScaleTicketConstants.scaleType.outbound;
    };

    const updateScaleTicketDetails = (
        fieldName: string,
        fieldValue: string | number | Date | null,
    ) => {
        const updatedDetails = {
            ...createScaleTicketDetails,
            scaleTicketBasicDetails: {
                ...createScaleTicketDetails.scaleTicketBasicDetails,
                [fieldName]: fieldValue,
            },
        };
        dispatch(setCreateScaleTicketDetails(updatedDetails));
    };

    const determineContractStatus = (): ScaleTicketContractStatus | null => {
        const contractId =
            +createScaleTicketDetails.scaleTicketBasicDetails.contractId;
        switch (contractId) {
            case EditScaleTicketConstants.contractType.noContract:
                return ScaleTicketContractStatus.NoContract;
            case EditScaleTicketConstants.contractType.assignContractLater:
                return ScaleTicketContractStatus.Unassigned;
            default:
                return null;
        }
    };

    const updateEditScaleType = (): void => {
        const scaleType = getScaleTypeFromPathname();
        updateScaleTicketDetails('scaleType', scaleType);
    };

    const scaleTicketDetailsHandler = (
        fieldName: string,
        fieldValue: string | number | Date | null,
        type?: string,
    ): void => {
        if (validateAccordingType(type, fieldValue)) {
            updateScaleTicketDetails(fieldName, fieldValue);
        }
    };

    const scaleTicketModalsHandler = (
        fieldName: string,
        fieldValue: string,
    ): void => {
        switch (fieldValue) {
            case scaleTicketLabelConstants.createContract:
                contractModal.open();
                break;
            case scaleTicketLabelConstants.createVendor:
                vendorModal.open();
                break;
            default:
                scaleTicketDetailsHandler(fieldName, fieldValue);
                break;
        }
    };

    const setScaleTicketPayload = (): ICreateScaleTicketPayload => {
        return {
            tDate: createScaleTicketDetails.scaleTicketBasicDetails.tDate,
            contractId: isCA9800On_ContractsMVP
                ? +createScaleTicketDetails.scaleTicketBasicDetails.contractId >
                  0
                    ? createScaleTicketDetails.scaleTicketBasicDetails
                          .contractId
                    : null
                : createScaleTicketDetails.scaleTicketBasicDetails.contractId,
            contractStatus: isCA9800On_ContractsMVP
                ? determineContractStatus()
                : undefined,
            commodityId: isCA9800On_ContractsMVP
                ? createScaleTicketDetails.scaleTicketBasicDetails.commodity
                : undefined,
            vendorId: isCA9800On_ContractsMVP
                ? createScaleTicketDetails.scaleTicketBasicDetails.vendor
                : undefined,
            price: createScaleTicketDetails.scaleTicketBasicDetails.price,
            scaleType:
                createScaleTicketDetails.scaleTicketBasicDetails.scaleType,
            scaleTickets: createScaleTicketDetails.scaleTicketSegments,
        };
    };

    const resetAllStates = useCallback(
        (scaleType?: number) => {
            dispatch(
                setCreateScaleTicketDetails({
                    ...SCALE_TICKET_INITIAL_STATE.createScaleTicketDetails,
                    scaleTicketBasicDetails: {
                        ...SCALE_TICKET_INITIAL_STATE.createScaleTicketDetails
                            .scaleTicketBasicDetails,
                        scaleType,
                    },
                }),
            );
            dispatch(
                setCreateScaleTicketDetailsError({
                    ...SCALE_TICKET_INITIAL_STATE.createScaleTicketDetailsError,
                }),
            );
        },
        [dispatch],
    );

    const onCancel = () => {
        resetAllStates();
        navigate(Constants.Routes.SCALE_TICKET);
    };

    const isMandatoryField = (
        fieldName: string,
        isSegmentField: boolean = false,
        index?: number,
    ) => {
        if (isSegmentField && index !== undefined) {
            const segmentField =
                createScaleTicketDetails.scaleTicketSegments[index][fieldName];
            return (
                (ScaleTicketConstants.mandatoryDropdown.includes(fieldName) &&
                    String(segmentField) === '0') ||
                (!segmentField &&
                    ScaleTicketConstants.mandatoryFields.includes(fieldName))
            );
        }

        const mainField =
            createScaleTicketDetails.scaleTicketBasicDetails[fieldName];
        return (
            (ScaleTicketConstants.mandatoryDropdown.includes(fieldName) &&
                String(mainField) === '0') ||
            (!mainField &&
                ScaleTicketConstants.mandatoryFields.includes(fieldName))
        );
    };

    const validateMandatoryDetails = () => {
        const detailsErrorCopy = deepClone(createScaleTicketDetailsError);
        let isValid = true;
        ScaleTicketConstants.basicScaleTicketMandatoryFields.forEach(
            fieldName => {
                if (isMandatoryField(fieldName)) {
                    detailsErrorCopy[fieldName] = true;
                    isValid = false;
                }
            },
        );

        detailsErrorCopy.scaleTicketSegmentError.forEach(
            (segmentError, index) => {
                const segmentFields = Object.keys(segmentError);
                segmentFields.forEach(fieldName => {
                    if (isMandatoryField(fieldName, true, index)) {
                        detailsErrorCopy.scaleTicketSegmentError[index][
                            fieldName
                        ] = true;
                        isValid = false;
                    }
                });
            },
        );

        if (!isValid) {
            dispatch(setCreateScaleTicketDetailsError(detailsErrorCopy));
        }
        return isValid;
    };

    const navigateToPreviousPage = () => {
        if (
            location.pathname.includes(
                Constants.Routes.UPDATE_INBOUND_SCALE_TICKETS,
            ) ||
            location.pathname.includes(
                Constants.Routes.UPDATE_OUTGOING_SCALE_TICKETS,
            )
        ) {
            navigate(-1);
        } else {
            const resetType =
                location.pathname ===
                Constants.Routes.CREATE_INBOUND_SCALE_TICKETS
                    ? 1
                    : 2;
            resetAllStates(resetType);
        }
    };

    const createScaleTicket = () => {
        if (feedlotId) {
            dispatch(
                createMultiLineScaleTicketService(
                    setScaleTicketPayload(),
                    feedlotId,
                    navigateToPreviousPage,
                    setShowError,
                ),
            );
        }
    };

    const onSubmit = () => {
        const isValidMandatoryFields = validateMandatoryDetails();
        if (isValidMandatoryFields) {
            dispatch(setLoading(true));
            createScaleTicket();
        } else {
            setShowError({
                error: true,
                errorMessage: ScaleTicketConstants.errorMessage.mandatoryFields,
                buttonText: '',
            });
        }
    };

    const closeErrorModalHandler = () => {
        setShowError({ error: false, errorMessage: '', buttonText: '' });
    };

    const handleCloseVendorModal = (
        isVendorCreate = false,
        vendorId?: number,
    ) => {
        vendorModal.close();
        if (isVendorCreate && vendorId) {
            const scaleTicketCopy = { ...createScaleTicketDetails };
            scaleTicketCopy.scaleTicketBasicDetails.vendor = vendorId;
            dispatch(setCreateScaleTicketDetails(scaleTicketCopy));
            dispatch(fetchVendor());
        }
    };

    useEffect(() => {
        const hasErrorInScaleTicketNumber =
            createScaleTicketDetailsError.scaleTicketSegmentError.some(
                segmentError => segmentError.scaleTicketNumber === true,
            );
        setIsSubmitDisabled(hasErrorInScaleTicketNumber);
    }, [createScaleTicketDetailsError.scaleTicketSegmentError]);

    useEffect(() => {
        dispatch(fetchVendor());
        dispatch(fetchTruckingCompanies());
    }, []);

    useEffect(() => {
        if (feedlotId) {
            dispatch(fetchCommodities(feedlotId));
            updateEditScaleType();
            const resetType =
                location.pathname ===
                Constants.Routes.CREATE_INBOUND_SCALE_TICKETS
                    ? 1
                    : 2;
            resetAllStates(resetType);
            const scaleType = resetType === 1 ? 'InBound' : 'OutBound';
            dispatch(fetchContract(feedlotId, scaleType));
        }
    }, [feedlotId, dispatch, location.pathname, resetAllStates]);

    return (
        <>
            {loading && <FullPageLoader />}
            {showError.error && (
                <ErrorModal
                    showModal={showError.error}
                    closeModalHandler={closeErrorModalHandler}
                    errorMessage={showError.errorMessage}
                    buttonText={showError.buttonText}
                />
            )}
            <CreateContractModal
                show={contractModal.isOpen}
                onHide={contractModal.close}
            />
            {isCA10570On_VendorList ? (
                <VendorModal
                    showModal={vendorModal.isOpen}
                    closeModalWithChanges={handleCloseVendorModal}
                    editVendorDetails={VENDOR_INITIAL_STATE.createVendorInitial}
                />
            ) : (
                <CreateVendorModal
                    show={vendorModal.isOpen}
                    onHide={vendorModal.close}
                />
            )}
            <div className="pt-4">
                <section className="main-content">
                    <div className="main-content-block">
                        <div className="white-content-block">
                            <CreateScaleTicketBasicDetails
                                scaleTicketDetailsHandler={
                                    scaleTicketDetailsHandler
                                }
                                scaleTicketModalsHandler={
                                    scaleTicketModalsHandler
                                }
                            />
                            <hr />
                            <div className="px-3 scale-ticket-form">
                                <CreateScaleTicketAdvanceDetailsHeaders />
                                <CreateScaleTicketAdvanceDetails
                                    scaleTicketDetailsErrorHandler={
                                        scaleTicketDetailsErrorHandler
                                    }
                                />
                            </div>

                            <EditScaleTicketFooter
                                onCancel={onCancel}
                                onSubmit={onSubmit}
                                isDisabled={isSubmitDisabled}
                            />
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default CreateScaleTicket;
