import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Store';
import { currentDate } from '../../../../helpers';
import {
    fetchContract,
    setContract,
    setCreateScaleTicketDetails,
    setCreateScaleTicketDetailsError,
} from '../actions/ScaleTicketActions';
import { SCALE_TICKET_INITIAL_STATE } from '../constants/scaleTicketsInitialState';
import {
    EditScaleTicketConstants,
    scaleTicketLabelConstants,
} from '../constants/ScaleTicketConstants';
import { ICreateScaleTicketBasicDetails } from '../interfaces/ScaleTicketInterface';
import Constants from '../../../../utils/Constants';
import AutocompleteDropdown from '../../../../components/common/autocompleteDropdown/AutocompleteDropdown';
import { feedlotManagerFeatures } from '../../../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { useFeedlotId } from '../../../../commonHooks/useFeedlotId';

const {
    isCA7808On_PreventFutureDateScaleTicket,
    isCA9800On_ContractsMVP,
    isCA11602On_ScaleTicketAutocomplete,
} = feedlotManagerFeatures;

const CreateScaleTicketBasicDetails = ({
    scaleTicketDetailsHandler,
    scaleTicketModalsHandler,
}: ICreateScaleTicketBasicDetails) => {
    const {
        createScaleTicketDetails,
        createScaleTicketDetailsError,
        vendors,
        commodities,
        completeContracts,
        contracts,
    } = useAppSelector(state => state.scaleTicket);
    const [changedBy, setChangedBy] = useState<
        'contract' | 'commodityVendor' | null
    >(null);
    const [disableFields, setDisableFields] = useState({
        contract: false,
        commodityVendor: false,
        dmPercentage: true,
    });
    const dispatch = useAppDispatch();
    const feedLotId = useFeedlotId();
    const commodityFieldRef = useRef<HTMLSelectElement | null>(null);

    const handleDateKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>,
    ): void => {
        if (e.key === Constants.EVENTS.TAB) {
            e.preventDefault();
            if (commodityFieldRef.current) {
                commodityFieldRef.current.focus();
            }
        }
    };

    const selectedContract = useMemo(
        () =>
            contracts.find(
                contract =>
                    contract.contractId ===
                    +createScaleTicketDetails.scaleTicketBasicDetails
                        .contractId,
            ),
        [
            contracts,
            createScaleTicketDetails.scaleTicketBasicDetails.contractId,
        ],
    );

    const willHaveContract = useCallback(
        () =>
            +createScaleTicketDetails.scaleTicketBasicDetails.contractId !==
            EditScaleTicketConstants.contractType.noContract,
        [createScaleTicketDetails.scaleTicketBasicDetails.contractId],
    );

    const contractIsPriced = useCallback(
        () => selectedContract?.price !== null,
        [selectedContract],
    );

    const handleCommodityVendorChange = (fieldName, fieldValue) => {
        setChangedBy('commodityVendor');
        setDisableFields({
            contract: true,
            commodityVendor: false,
            dmPercentage: true,
        });
        scaleTicketModalsHandler(fieldName, fieldValue);
    };
    const resetScaleTicketDetails = ({
        price = '',
        commodity = 0,
        vendor = 0,
        dmPercentage = 0,
    } = {}) => {
        return {
            ...createScaleTicketDetails,
            scaleTicketBasicDetails: {
                ...createScaleTicketDetails.scaleTicketBasicDetails,
                price,
                commodity,
                vendor,
                dmPercentage,
            },
        };
    };

    useEffect(() => {
        if (changedBy === 'commodityVendor') {
            const { commodity, vendor } =
                createScaleTicketDetails.scaleTicketBasicDetails;

            if ((!+commodity && !+vendor) || (+commodity && +vendor)) {
                setDisableFields({
                    contract: false,
                    commodityVendor: false,
                    dmPercentage: true,
                });
                if (!+commodity && !+vendor && feedLotId) {
                    const scaleType =
                        createScaleTicketDetails.scaleTicketBasicDetails
                            .scaleType === 1
                            ? 'InBound'
                            : 'OutBound';
                    dispatch(fetchContract(feedLotId, scaleType));
                    setChangedBy(null);
                } else {
                    const filteredContractResponse = completeContracts.filter(
                        contractItem =>
                            +commodity === contractItem.commodityId &&
                            +vendor === contractItem.vendorId,
                    );

                    dispatch(
                        setContract([
                            ...SCALE_TICKET_INITIAL_STATE.contracts,
                            ...(filteredContractResponse ?? []),
                        ]),
                    );

                    if (
                        !Number(
                            createScaleTicketDetails.scaleTicketBasicDetails
                                .contractId,
                        )
                    ) {
                        const { id: contractId, price } =
                            filteredContractResponse.length === 1
                                ? filteredContractResponse[0]
                                : { id: 0, price: '' };
                        const updatedDetails = {
                            ...createScaleTicketDetails,
                            scaleTicketBasicDetails: {
                                ...createScaleTicketDetails.scaleTicketBasicDetails,
                                contractId: +contractId,
                                price,
                            },
                        };
                        dispatch(setCreateScaleTicketDetails(updatedDetails));
                    }
                }
            }
        }
    }, [
        createScaleTicketDetails.scaleTicketBasicDetails.commodity,
        createScaleTicketDetails.scaleTicketBasicDetails.vendor,
        dispatch,
    ]);

    useEffect(() => {
        const { contractId } = createScaleTicketDetails.scaleTicketBasicDetails;
        if (+contractId > 0) {
            const selectedContract = completeContracts.find(
                contractItem => +contractId === contractItem.contractId,
            );

            if (selectedContract && +selectedContract.id > 0) {
                if (changedBy !== 'commodityVendor') {
                    setChangedBy('contract');
                    setDisableFields({
                        contract: false,
                        commodityVendor: true,
                        dmPercentage: true,
                    });
                }

                dispatch(
                    setCreateScaleTicketDetails(
                        resetScaleTicketDetails({
                            price: String(selectedContract.price),
                            commodity: selectedContract.commodityId || 0,
                            vendor: selectedContract.vendorId || 0,
                            dmPercentage: selectedContract.dmPercentage || 0,
                        }),
                    ),
                );

                let errorCopy = { ...createScaleTicketDetailsError };
                errorCopy = {
                    ...errorCopy,
                    contractId: false,
                    commodity: !selectedContract.commodityId,
                    vendor: !selectedContract.vendorId,
                };
                dispatch(setCreateScaleTicketDetailsError(errorCopy));
            }
        } else {
            if (changedBy === 'contract') {
                setDisableFields({
                    contract: false,
                    commodityVendor: false,
                    dmPercentage: true,
                });

                dispatch(
                    setCreateScaleTicketDetails(resetScaleTicketDetails()),
                );
            }
        }
    }, [
        dispatch,
        createScaleTicketDetails.scaleTicketBasicDetails.contractId,
        completeContracts,
    ]);

    return (
        <>
            <div className="px-3 d-flex scale-ticket-form pb-2">
                <div className="col col-2">
                    <Form.Group>
                        <Form.Label>
                            {EditScaleTicketConstants.labels.date}
                            <span className="necessaryFields">*</span>
                        </Form.Label>
                        <Form.Control
                            className={`text-secondary form-item ${
                                createScaleTicketDetailsError.tDate
                                    ? 'border-danger'
                                    : ''
                            } rounded`}
                            name={EditScaleTicketConstants.apiFields.tDate}
                            max={
                                isCA7808On_PreventFutureDateScaleTicket
                                    ? currentDate('YYYY-MM-DD')
                                    : undefined
                            }
                            value={
                                createScaleTicketDetails.scaleTicketBasicDetails
                                    .tDate
                            }
                            onChange={e =>
                                scaleTicketDetailsHandler(
                                    e.target.name,
                                    e.target.value,
                                )
                            }
                            type="date"
                            size="sm"
                            onKeyDown={handleDateKeyDown}
                        />
                    </Form.Group>
                </div>
                <div className="col col-2">
                    <Form.Group>
                        <Form.Label>
                            {EditScaleTicketConstants.labels.contract}
                            <span className="necessaryFields">*</span>
                        </Form.Label>
                        {isCA11602On_ScaleTicketAutocomplete ? (
                            <AutocompleteDropdown
                                selected={
                                    createScaleTicketDetails
                                        .scaleTicketBasicDetails.contractId
                                }
                                inputName={
                                    EditScaleTicketConstants.apiFields
                                        .contractId
                                }
                                nameKey="name"
                                valueKey="id"
                                options={contracts}
                                onChange={e => {
                                    scaleTicketModalsHandler(
                                        e.target.name,
                                        e.target.value,
                                    );
                                }}
                                placeholder={
                                    scaleTicketLabelConstants.selectContract
                                }
                                disabled={disableFields.contract}
                                className="text-secondary form-item"
                                styleError={
                                    createScaleTicketDetailsError.contractId
                                }
                            />
                        ) : (
                            <Form.Control
                                name={
                                    EditScaleTicketConstants.apiFields
                                        .contractId
                                }
                                value={
                                    createScaleTicketDetails
                                        .scaleTicketBasicDetails.contractId
                                }
                                className={`text-secondary form-item border border-${
                                    createScaleTicketDetailsError.contractId
                                        ? 'danger'
                                        : ''
                                } rounded`}
                                onChange={e => {
                                    scaleTicketModalsHandler(
                                        e.target.name,
                                        e.target.value,
                                    );
                                }}
                                as="select"
                                size="sm"
                                disabled={disableFields.contract}
                            >
                                {contracts?.map((contract, index) => (
                                    <option key={index} value={contract.id}>
                                        {contract.name}
                                    </option>
                                ))}
                            </Form.Control>
                        )}
                    </Form.Group>
                </div>
                <div className="col col-2">
                    <Form.Group>
                        <Form.Label>
                            {EditScaleTicketConstants.labels.commodity}
                            <span className="necessaryFields">*</span>
                        </Form.Label>
                        {isCA11602On_ScaleTicketAutocomplete ? (
                            <AutocompleteDropdown
                                options={commodities}
                                selected={
                                    createScaleTicketDetails
                                        .scaleTicketBasicDetails.commodity
                                }
                                placeholder={
                                    scaleTicketLabelConstants.selectCommodity
                                }
                                nameKey="name"
                                valueKey="id"
                                onChange={e =>
                                    handleCommodityVendorChange(
                                        e.target.name,
                                        e.target.value,
                                    )
                                }
                                inputName={
                                    EditScaleTicketConstants.apiFields.commodity
                                }
                                className="text-secondary form-item"
                                styleError={
                                    createScaleTicketDetailsError.commodity
                                }
                                disabled={disableFields.commodityVendor}
                            />
                        ) : (
                            <Form.Control
                                name={
                                    EditScaleTicketConstants.apiFields.commodity
                                }
                                value={
                                    createScaleTicketDetails
                                        .scaleTicketBasicDetails.commodity
                                }
                                className={`text-secondary form-item border border-${
                                    createScaleTicketDetailsError.commodity
                                        ? 'danger'
                                        : ''
                                } rounded`}
                                onChange={e =>
                                    handleCommodityVendorChange(
                                        e.target.name,
                                        e.target.value,
                                    )
                                }
                                as="select"
                                size="sm"
                                ref={commodityFieldRef}
                                disabled={disableFields.commodityVendor}
                            >
                                {commodities?.map((commodity, index) => (
                                    <option key={index} value={commodity.id}>
                                        {commodity.name}
                                    </option>
                                ))}
                            </Form.Control>
                        )}
                    </Form.Group>
                </div>

                <div className="col col-2">
                    <Form.Group>
                        <Form.Label>
                            {EditScaleTicketConstants.labels.vendor}
                            <span className="necessaryFields">*</span>
                        </Form.Label>
                        <AutocompleteDropdown
                            options={vendors}
                            nameKey="name"
                            valueKey="id"
                            onChange={e =>
                                handleCommodityVendorChange(
                                    e.target.name,
                                    e.target.value,
                                )
                            }
                            inputName={
                                EditScaleTicketConstants.apiFields.vendor
                            }
                            placeholder={scaleTicketLabelConstants.selectVendor}
                            selected={
                                createScaleTicketDetails.scaleTicketBasicDetails
                                    .vendor
                            }
                            styleError={createScaleTicketDetailsError.vendor}
                            disabled={disableFields.commodityVendor}
                        />
                    </Form.Group>
                </div>
                {isCA9800On_ContractsMVP && (
                    <div className="col col-2">
                        <Form.Group>
                            <Form.Label>
                                {EditScaleTicketConstants.labels.dmPercentage}
                            </Form.Label>
                            <Form.Control
                                name={
                                    EditScaleTicketConstants.apiFields
                                        .dmPercentage
                                }
                                value={
                                    createScaleTicketDetails
                                        .scaleTicketBasicDetails.dmPercentage
                                }
                                placeholder={
                                    EditScaleTicketConstants.placeholders.price
                                }
                                className={`text-secondary form-item rounded`}
                                onChange={e => {
                                    scaleTicketDetailsHandler(
                                        e.target.name,
                                        e.target.value,
                                        'percentage',
                                    );
                                }}
                                type="text"
                                size="sm"
                                disabled={disableFields.dmPercentage}
                            />
                        </Form.Group>
                    </div>
                )}
                <div className="col col-2">
                    <Form.Group>
                        <Form.Label>
                            {EditScaleTicketConstants.labels.price}
                        </Form.Label>
                        <Form.Control
                            name={EditScaleTicketConstants.apiFields.price}
                            value={
                                createScaleTicketDetails.scaleTicketBasicDetails
                                    .price ?? ''
                            }
                            placeholder={
                                willHaveContract() && contractIsPriced()
                                    ? EditScaleTicketConstants.placeholders
                                          .price
                                    : ''
                            }
                            onChange={
                                isCA9800On_ContractsMVP
                                    ? e => {
                                          scaleTicketDetailsHandler(
                                              e.target.name,
                                              e.target.value,
                                          );
                                      }
                                    : undefined
                            }
                            className="text-secondary form-item"
                            type="text"
                            size="sm"
                            disabled={
                                isCA9800On_ContractsMVP
                                    ? willHaveContract()
                                    : true
                            }
                        />
                    </Form.Group>
                </div>
            </div>
        </>
    );
};

export default CreateScaleTicketBasicDetails;
