import { createContext, useContext, useState } from 'react';
import { IOwner, IOwnerContext } from './ownerContextInterface';
import {
    getOwnerFromOwnerId,
    getSelectedOwnerIdFromStorage,
    mapCustomersToOwners,
    setSelectedOwnerIdToStorage,
} from './ownerContextHelpers';
import { useCustomers } from '../../utils/financialAppHooks';
import useAuthContext from '../../apps/common/modules/auth/hooks/useAuthContext';
import { SideNavItems } from '../../utils/constants/RoleConstants';
import { AppRoles } from '../../apps/common/modules/auth/constants/AuthConstants';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { useAppSelector } from '../../Redux/Store';
import { sideNavTabEnum } from '../../utils/Interface/CommonInterface';

const { isCA11160n_OwnerContextApiCall } = feedlotManagerFeatures;

export const OwnerContext = createContext<IOwnerContext>({
    owners: [],
    selectedOwner: {} as IOwner,
    updateSelectedOwner: () => undefined,
    updateOwnersList: () => undefined,
    //TODO!!! add ownerSettings property here once settings are changes to a perOwner basis. Can do the same thing with interestRates and statements once all on perOwner basis.
});

export const useOwnerContext = () => {
    const context = useContext(OwnerContext);
    if (!context) {
        throw new Error('Owner context issue');
    }
    return context;
};

export const OwnerContextProvider = ({
    children,
}: {
    children: JSX.Element | JSX.Element[];
}) => {
    const [shouldUpdateOwnersList, setShouldUpdateOwnersList] = useState(0);
    const { getUsersRoles, getUsersAppSubscriptions } = useAuthContext();
    const userRoles = getUsersRoles();
    const userAppSubscriptions = getUsersAppSubscriptions();
    const { currentSelectedTab } = useAppSelector(state => state.common);
    const financialAppRoles = new Set(SideNavItems.financialSideNavItem.roles);
    const canAccessCustomer =
        userAppSubscriptions.includes(AppRoles.CattleFinanceManager) &&
        userRoles.some(userRole => financialAppRoles.has(userRole));
    const customers =
        useCustomers(
            { sort: 'name' },
            [shouldUpdateOwnersList],
            canAccessCustomer &&
                (isCA11160n_OwnerContextApiCall
                    ? currentSelectedTab === sideNavTabEnum.Financial
                    : true),
        ) || [];

    const owners = mapCustomersToOwners(customers);
    const [selectedOwnerId, setSelectedOwnerId] = useState(
        getSelectedOwnerIdFromStorage(),
    );
    const selectedOwner = getOwnerFromOwnerId(owners, selectedOwnerId);

    const updateSelectedOwner = (ownerId: string) => {
        setSelectedOwnerId(ownerId);
        setSelectedOwnerIdToStorage(ownerId);
    };

    const updateOwnersList = () => {
        setShouldUpdateOwnersList(shouldUpdateOwnersList + 1);
    };

    return !selectedOwner ? null : (
        <OwnerContext.Provider
            value={{
                owners,
                selectedOwner,
                updateSelectedOwner,
                updateOwnersList,
            }}
        >
            {children}
        </OwnerContext.Provider>
    );
};
